<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__header">
        <a href="#!" class="footer__logo"><img src="/images/logo.svg" /></a>
        <button
          class="footer__header-button btn btn-sm btn-red"
          @click="openModal"
        >
          Заказать звонок
        </button>
      </div>
      <div class="footer__middle">
        <div class="footer__item">
          <h5 class="footer__item-title">Контакты</h5>
          <div class="footer__item-text">
            ЛНР, М.О Нововоайдарский, пгт Новоайдар, ул. Октябрьская, д. 2А
          </div>
          <div class="footer__item-bottom">
            <div class="footer__contact">
              <span> +7 (3412) 24–05–10</span>
              <a href="#!" @click="openModal">Заказать звонок</a>
            </div>
            <div class="footer__socials">
              <a href="#!" class="footer__socials-item">
                <img src="/images/icons/telegram.svg" />
              </a>
              <a href="#!" class="footer__socials-item">
                <img src="/images/icons/whatsapp.svg" />
              </a>
              <a href="#!" class="footer__socials-item">
                <img src="/images/icons/wk.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="footer__item">
          <h5 class="footer__item-title">О компании</h5>
          <ul class="footer__item-links">
            <li><a href="#!">О компании</a></li>
            <li><a href="#!">Контакты</a></li>
            <li><a href="#!">Вакансии</a></li>
            <li><a href="#!">Новости и акции</a></li>
            <li><a href="#!">FAQ</a></li>
          </ul>
        </div>
        <div class="footer__item">
          <h5 class="footer__item-title">Продукция</h5>
          <ul class="footer__item-links">
            <li><a href="#!">Бетон</a></li>
            <li><a href="#!">ЖБИ</a></li>
          </ul>
        </div>
        <div class="footer__item">
          <h5 class="footer__item-title">Услуги</h5>
          <ul class="footer__item-links">
            <li><a href="#!">Лаборатория</a></li>
            <li><a href="#!">Аренда спецтехники</a></li>
            <li><a href="#!">Консультация</a></li>
          </ul>
        </div>
      </div>
      <div class="footer__bottom">
        <p>© 2024 «Энки Бетон ЛНР»</p>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["openModal"]),
  },
};
</script>
<style scoped>
.footer {
  background: #1a2133;
}
.footer__header {
  padding: 34px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgb(255, 255, 255, 0.2);
}
.footer__logo img {
  width: 193.14px;
}
.footer__header .btn-sm {
  height: 72px;
}
.footer__middle {
  padding: 54px 0;
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: white;
  border-bottom: 2px solid rgb(255, 255, 255, 0.2);
}
.footer__item:first-child {
  max-width: 398px;
}
.footer__item-bottom {
  margin-top: 34px;
}
.footer__contact {
  display: grid;
  text-align: left;
  gap: 4px;
}

.footer__item-title {
  font-size: 21px;
  font-weight: 600;
  line-height: 26.25px;
  color: rgb(255, 255, 255, 0.5);
  margin-bottom: 24px;
}
.footer__item-links {
  display: grid;
  gap: 14px;
}
.footer__item-links li a {
  font-size: 19px;
  font-weight: 400;
  line-height: 28.5px;
  color: white;
  transition: 0.2s;
}
.footer__item-links li a:hover {
  color: #bf4017;
}
.footer__item-text {
  font-size: 19px;
  font-weight: 400;
  line-height: 28.5px;
}
.footer__item-bottom {
  display: flex;
  align-items: center;
  gap: 34px;
}
.footer__contact span {
  font-size: 21px;
  font-weight: 600;
  line-height: 25.6px;
  color: white;
}
.footer__contact a {
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  color: #bf4017;
  text-decoration: underline;
  transition: 0.2s;
}
.footer__contact a:hover {
  color: white;
}
.footer__socials {
  display: flex;
  align-items: center;
  gap: 14px;
}
.footer__socials-item {
  width: 46px;
  height: 46px;
  display: grid;
  place-items: center;
  background: #303747;
  border-radius: 50%;
  transition: 0.2s;
}
.footer__socials-item:hover {
  background: #bf4017;
}
.footer__bottom {
  padding: 24px 0;
}
.footer__bottom p {
  font-size: 19px;
  font-weight: 400;
  line-height: 28.5px;
  color: white;
  opacity: 50%;
}
@media (max-width: 1279px) {
  .footer__item:first-child {
    max-width: 325px;
  }
  .footer__item-bottom {
    display: grid;
    gap: 34px;
  }
}
@media (max-width: 1023px) {
  .footer__item:nth-child(4),
  .footer__item:nth-child(3) {
    display: none;
  }
  .footer__item-bottom {
    display: grid;
    gap: 34px;
  }
}
@media (max-width: 767px) {
  .footer__header-button {
    display: none;
  }
}
@media (max-width: 640px) {
  .footer__header {
    padding: 24px 0;
  }
  .footer__middle {
    padding: 34px 0;
  }
  .footer__item:first-child {
    max-width: 90%;
  }
  .footer__header-button {
    display: none;
  }
  .footer__item:nth-child(2) {
    display: none;
  }
  .footer__bottom p {
    font-size: 17px;
    font-weight: 400;
    line-height: 25.5px;
  }
  .footer__item-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }
  .footer__item-text {
    font-size: 17px;
    font-weight: 400;
    line-height: 25.5px;
  }
  .footer__contact span {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    color: white;
  }
  .footer__contact a {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    color: #bf4017;
    text-decoration: underline;
    transition: 0.2s;
  }
}
</style>
