<template>
  <section class="about">
    <div class="about__wrapper">
      <div class="container">
        <div class="about__left">
          <h2 class="about__left-title">О предприятии</h2>
          <p class="about__left-text">
            — Компания «Энки-Бетон» предлагает приобрести бетон в ЛНР любыми
            партиями. Нами разработаны привлекательные программы для постоянных
            и оптовых клиентов.
          </p>
          <div class="about__buttons">
            <button class="btn btn-max btn-red">Читать подробнее</button>
          </div>
        </div>
        <div class="about__right"><Form /></div>
      </div>
    </div>
  </section>
</template>
<script>
import Form from "@/components/Form.vue";
export default { components: { Form } };
</script>
<style scoped>
.about {
  background: url("../../../public/images/about_bg.jpg") no-repeat;
  background-size: cover;
  position: relative;
}
.about::after {
  content: url("../../../public/images/big_angle.png");
  position: absolute;
  z-index: 7;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.about__wrapper {
  position: relative;
}

.about__wrapper::after {
  content: "";
  width: 100%;
  height: 395px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: linear-gradient(
    -180deg,
    #1a2133 23.27%,
    rgba(26, 33, 51, 0) 100%
  );
}
.about__wrapper::before {
  content: "";
  width: 100%;
  height: 248px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: linear-gradient(0deg, #1a2133 23.27%, rgba(26, 33, 51, 0) 100%);
}
.about .about__wrapper .container {
  display: grid;
  grid-template-columns: auto 408px;
  align-items: center;
  padding: 83px 27px;
  padding-right: 80px !important;
  position: relative;
  z-index: 10;
}
.about__left {
  max-width: 556px;
  color: white;
}
.about__left-title {
  font-size: 46px;
  font-weight: 600;
  line-height: 57.5px;
}
.about__left-text {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.about__buttons {
  margin-top: 57px;
}
@media (max-width: 1279px) {
  .about .about__wrapper .container {
    grid-template-columns: auto 408px;
    gap: 50px;
    padding-right: 27px !important;
  }
}
@media (max-width: 1023px) {
  .about .about__wrapper .container {
    grid-template-columns: auto;
    gap: 70px;
  }
  .about__left {
    max-width: 100%;
  }
  .form__checkbox p {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .about::after {
    display: none;
  }
  .about .about__wrapper .container {
    padding: 70px 27px;
  }
  .about__left-title {
    font-size: 35px;
    font-weight: 600;
  }
  .about__left-text {
    font-size: 18px;
  }
}
</style>
