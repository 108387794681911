<template>
  <section class="news section-padding">
    <div class="container">
      <div class="news__header section-header">
        <h2 class="news__header-title">Новости</h2>
        <div class="carousel__controllers">
          <button @click="prev">
            <img src="/images/icons/angle_left.svg" />
          </button>
          <button @click="next">
            <img src="/images/icons/angle_right.svg" />
          </button>
        </div>
      </div>
      <div class="news__cards news__carousel owl-carousel">
        <div class="news__card" v-for="item of news_cards" :key="item.id">
          <div class="news__card-img">
            <img :src="item.img" />
          </div>
          <div class="news__card-bottom">
            <p class="news__card-title">{{ item.title }}</p>
            <div class="news__card-link">
              <a href="#!">Читать подробнее</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      news_cards: [
        {
          id: 1,
          img: "/images/news/img1.png",
          title: "Прожолжается строительство кирпичног...",
        },
        {
          id: 2,
          img: "/images/news/img2.png",
          title: "Как ухаживать за бетоном, который был залит зимой?",
        },
        {
          id: 3,
          img: "/images/news/img3.png",
          title: "Как выбрать нужную марку бетона?",
        },
      ],
    };
  },
  mounted() {
    this.initializeCarousel();
  },
  methods: {
    initializeCarousel() {
      $(".news__carousel").owlCarousel({
        items: 3,
        margin: 24,
        loop: true,
        dots: false,
        responsive: {
          0: {
            items: 1,
          },
          450: {
            items: 1.3,
          },
          500: {
            items: 1.5,
          },
          640: {
            items: 2,
          },
          1024: {
            items: 3,
          },
        },
      });
    },
    prev() {
      $(".news__carousel").trigger("prev.owl.carousel");
    },
    next() {
      $(".news__carousel").trigger("next.owl.carousel");
    },
  },
};
</script>
<style scoped>
.section-padding {
  padding-top: 100px;
}

.news__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news__card {
  display: grid;
  align-items: center;
  padding: 34px;
  padding-bottom: 25px;
  border: 2px solid #1a21334d;
  border-radius: 6px;
}
.news__card:hover .news__card-img img {
  transform: scale(110%);
}
.news__card-img {
  width: 100%;
  height: 204px;
  border-radius: 6px;
  overflow: hidden;
}
.news__card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
.news__card-bottom {
  margin-top: 24px;
}
.news__card-title {
  font-size: 21px;
  font-weight: 600;
  line-height: 26.25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news__card-link {
  margin-top: 24px;
}
.news__card-link a {
  font-size: 19px;
  font-weight: 600;
  line-height: 28.5px;
  color: #bf4017;
  text-decoration: underline;
  transition: 0.2s;
}
.news__card-link a:hover {
  color: #1a2133;
}
@media (max-width: 1279px) {
  .news__card {
    padding: 24px;
    padding-bottom: 25px;
  }
  .news__card-img {
    height: 190px;
  }
}
@media (max-width: 768px) {
  .section-padding {
    padding-top: 70px;
  }
}
@media (max-width: 640px) {
  .news__card-img {
    height: 194px;
  }

  .news__card-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22.5px;
    -webkit-line-clamp: 2;
  }
  .news__card-link a {
    font-size: 17px;
    font-weight: 600;
    line-height: 25.5px;
  }
}
</style>
