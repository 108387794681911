<template>
  <form class="form" @submit.prevent>
    <div class="form__header">
      <h3 class="form__header-title">Оставить заявку</h3>
      <p class="form__header-text">Перезвоним для обсуждения деталей</p>
    </div>
    <div class="form__inputs">
      <input type="text" placeholder="Ваше имя" />
      <input
        type="text"
        placeholder="Номер телефона"
        v-mask="'+7 (####) ###-##-##'"
      />
    </div>
    <div class="form__checkbox">
      <input type="checkbox" />
      <p>Соглашаюсь с <a href="#!">обработкой своих персональных данных</a></p>
    </div>
    <div class="form__buttons">
      <button class="btn btn-mid btn-red">Оставить заявку</button>
    </div>
  </form>
</template>

<script>
export default {};
</script>

<style scoped>
.btn-red:hover {
  background: #1a2133;
  color: white;
}
.form {
  padding: 34px;
  background: white;
  padding-bottom: 38px;
  border-radius: 6px;
}
.form__header-text {
  margin-top: 14px;
  font-size: 19px;
  font-weight: 400;
  line-height: 28.5px;
  color: #1a2133;
}
.form__inputs {
  margin-top: 24px;
  display: grid;
  gap: 14px;
}
.form__inputs input {
  width: 100%;
  height: 66px;
  padding: 0 24px;
  border: 2px solid #1a213366;
  border-radius: 6px;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #1a2133;
}
.form__inputs input:focus {
  border: 2px solid #bf4017;
}

.form__inputs input::placeholder {
  color: rgb(26, 33, 51, 0.5);
}
.form__checkbox {
  margin-top: 18px;
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto;
  gap: 14px;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 23px;
  height: 23px;
  border: 2px solid #bf4017;
  border-radius: 3px;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: #bf4017;
  border-color: #bf4017;
}

input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  top: px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-radius: 2px;
  border: 3px solid white;
}

.form__checkbox p {
  max-width: 303px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #8c8f98;
}

.form__checkbox a {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #1a2133;
  text-decoration: underline;
  transition: 0.2s;
}
.form__checkbox a:hover {
  color: #bf4017;
}
.form__buttons {
  margin-top: 44px;
}
.form__buttons button {
  width: 100%;
}
@media (max-width: 1023px) {
  .form__checkbox p {
    max-width: 100%;
  }
  .form__buttons button {
    padding: 0 61px;
    height: 76px;
    font-size: 17px;
    font-weight: 600;
    line-height: 25.5px;
  }
}
@media (max-width: 767px) {
  .form {
    padding: 24px;
    padding-bottom: 28px;
  }
}
@media (max-width: 576px) {
  .form__header-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 27.5px;
  }
  .form__header-text {
    font-size: 17px;
    font-weight: 400;
    line-height: 25.5px;
  }
  .form__inputs input {
    font-size: 17px;
  }
  .form__checkbox p,
  .form__checkbox a {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
