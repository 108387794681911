<template>
  <section class="home">
    <div class="container">
      <div class="home__text">
        <h1 class="home__text-title">Продажа и доставка от производителя</h1>
        <p>
          — Профессиональный производитель всех классов товарного бетона и
          раствора по новейшим стандартам и технологиям.
        </p>
        <div class="home__buttons">
          <button class="btn btn-max btn-red" @click="openModal">
            Бесплатная консультация
          </button>
          <!-- <div class="home__buttons-right">
            <div class="play__button">
              <button>
                <img src="/images/icons/download_button.svg" />
              </button>
            </div>
            <p><span>Cкачать</span> <span>PDF презентацию</span></p>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["openModal"]),
  },
};
</script>
<style scoped>
.home {
  position: relative;
  padding: 168px 0;
}
.home::before {
  content: "";
  width: 100%;
  height: 273px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: linear-gradient(0deg, #1a2133 23.27%, rgba(26, 33, 51, 0) 100%);
}
.home .container {
  position: relative;
  z-index: 8;
}
.home__text {
  color: white;
}
.home__text-title {
  max-width: 818px;
  font-size: 66px;
  font-weight: 600;
  line-height: 82.5px;
}
.home__text p {
  max-width: 596px;
  margin-top: 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.home__buttons {
  /* max-width: 1010px; */
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 54px;
}
/* .home__buttons-right {
  position: relative;
  display: flex;
  justify-content: center;
}
.play__button {
  display: flex;
  justify-content: center;
}
.play__button button {
  text-align: center;
  width: 78px;
  height: 78px;
  display: grid;
  place-items: center;
  background: #bf401780;
  border-radius: 50%;
}
.play__button button img {
  width: 54px;
  height: 54px;
}
.home__buttons-right p {
  position: absolute;
  bottom: -68px;
  text-align: center;
  width: 179px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: white;
  opacity: 50%;
  display: grid;
}
.home__buttons-right p span {
  width: 100%;
} */
@media (max-width: 1024px) {
  .home__text-title {
    max-width: 650px;
    font-size: 55px;
    line-height: normal;
  }
  .home__text p {
    max-width: 596px;
    margin-top: 24px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }
}
@media (max-width: 650px) {
  .home__text-title {
    font-size: 38px;
    font-weight: 600;
    line-height: 47.5px;
  }
  .home__text p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
}
@media (max-width: 576px) {
  .home {
    padding: 100px 0;
    padding-bottom: 70px;
  }
  .home::before {
    display: none;
  }
}
</style>
