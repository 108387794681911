<template>
  <main class="home__page">
    <div class="home__top">
      <Header class="header__block" />
      <Home class="home__section" />
    </div>
    <Products
      data-aos="fade-up"
      data-aos-duration="400"
      data-aos-delay="400"
      data-aos-easing="ease-in-out"
      class="products__section"
    />
    <Service
      data-aos="fade-up"
      data-aos-duration="400"
      data-aos-delay="400"
      data-aos-easing="ease-in-out"
      class="service__section"
    />
    <About
      data-aos="fade-up"
      data-aos-duration="400"
      data-aos-delay="400"
      data-aos-easing="ease-in-out"
      class="about__section"
    />
    <News
      data-aos="fade-up"
      data-aos-duration="400"
      data-aos-delay="400"
      data-aos-easing="ease-in-out"
      class="news__section"
    />
    <Contact
      data-aos="fade-up"
      data-aos-duration="400"
      data-aos-delay="400"
      data-aos-easing="ease-in-out"
      class="contact__section"
    />
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import Home from "@/components/Home/Home.vue";
import Products from "@/components/Home/Products.vue";
import Service from "@/components/Home/Service.vue";
import About from "@/components/Home/About.vue";
import News from "@/components/Home/News.vue";
import Contact from "@/components/Home/Contact.vue";
import AOS from "aos";

export default {
  components: {
    Header,
    Home,
    Products,
    Service,
    About,
    News,
    Contact,
  },
  mounted() {
    AOS.refresh();
  },
};
</script>

<style scoped>
.home__top {
  background: url("../../public/images/home_bg.jpg") no-repeat;
  position: relative;
  background-size: cover;
}
.home__top::after {
  content: url("../../public/images/big_angle.png");
  position: absolute;
  z-index: 7;
  top: 0;
  right: 0;
}
.home__top::before {
  content: "";
  width: 100%;
  height: 459px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: linear-gradient(
    -180deg,
    #1a2133 23.27%,
    rgba(26, 33, 51, 0) 100%
  );
}
@media (max-width: 768px) {
  .home__top::after {
    display: none;
  }
}
@media (max-width: 576px) {
  .home__top {
    background: url("../../public/images/home_media_bg.jpg") no-repeat;
    position: relative;
    background-size: cover;
  }
}
</style>
