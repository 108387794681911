<template>
  <main class="contact__page">
    <div class="contact__top">
      <HeaderSticky class="header__block" />
    </div>
  </main>
</template>

<script>
import HeaderSticky from "@/components/HeaderSticky.vue";
export default {
  components: {
    HeaderSticky,
  },
};
</script>
