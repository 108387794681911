<template>
  <section class="service section-padding">
    <div class="container">
      <div class="service__header section-header">
        <h2 class="service__header-title">Наши услуги</h2>
      </div>
      <div class="service__cards">
        <div class="service__card">
          <div class="service__card-img">
            <img src="/images/icons/service_icons/chemistry_icon.svg" />
          </div>
          <p class="service__card-title">Услуги лаборатории</p>
          <div class="service__card-links">
            <a href="#!" class="service__card-link"> Подробнее </a>
          </div>
        </div>
        <div class="service__card">
          <div class="service__card-img">
            <img src="/images/icons/service_icons/key_icon.svg" />
          </div>
          <p class="service__card-title">Аренда спецтехники</p>
          <div class="servie__card-links">
            <a href="#!" class="service__card-link"> Подробнее </a>
          </div>
        </div>
        <div class="service__card service__card-last">
          <p class="service__card-title">Бесплатная консультация</p>
          <div class="service__card-links">
            <a href="#!" class="service__card-link" @click="openModal">
              Заказать звонок
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["openModal"]),
  },
};
</script>
<style scoped>
.section-padding {
  padding-bottom: 110px;
}
.service__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}
.service__card {
  display: grid;
  grid-template-rows: max-content auto auto;
  padding: 24px 34px;
  border-radius: 6px;
  border: 2px solid #bf4017;
  background: white;
}

.service__card-img {
  margin-bottom: 14px;
}
.service__card-title {
  font-size: 21px;
  font-weight: 600;
  line-height: 26.25px;
  color: #1a2133;
  margin-bottom: 14px;
}
.service__card-links {
  height: 100%;
  align-items: end;
}

.service__card-link {
  height: 100%;
  font-size: 19px;
  font-weight: 600;
  line-height: 28.5px;
  color: #bf4017;
  text-decoration: underline;
  transition: 0.2s;
}
.service__card-link:hover {
  color: #1a2133;
}
/* last service card  */
.service__card-last {
  grid-template-rows: max-content auto;
  background: #bf4017;
}
.service__card-last .service__card-title {
  max-width: 316px;
  font-size: 23px;
  font-weight: 600;
  line-height: 31.05px;
  color: white;
}
.service__card-links {
  display: flex;
  align-items: end;
}
.service__card-link {
  height: max-content;
}
.service__card-last .service__card-link {
  color: white;
}
.service__card-last .service__card-link:hover {
  color: #1a2133;
}
@media (max-width: 1280px) {
  .service__card {
    padding: 24px 25px;
  }
}
@media (max-width: 1024px) {
  .service__cards {
    grid-template-columns: 1fr 1fr;
  }
  .service__card-last .service__card-title {
    font-size: 21px;
    line-height: 26.25px;
  }
  .service__card-last .service__card-link {
    margin-top: 51px;
  }
}
@media (max-width: 768px) {
  .section-padding {
    padding-bottom: 80px;
  }
}
@media (max-width: 576px) {
  .service__cards {
    grid-template-columns: 1fr;
  }
  .service__card-last {
    height: 192px;
  }
  .service__card-img img {
    width: 54px;
  }
  .service__card-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22.5px;
  }
  .service__card-link {
    font-size: 17px;
    font-weight: 600;
    line-height: 25.5px;
  }
  .service__card-last .service__card-title {
    max-width: 90%;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
  }
}
</style>
