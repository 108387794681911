<template>
  <div class="modal">
    <div class="modal__wrapper">
      <div class="modal__header">
        <div class="modal__header-xmark" @click="closeModal">
          <img src="/images/icons/xmark.svg" />
        </div>
      </div>
      <Form class="modal__form" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Form from "../Form.vue";
export default {
  components: {
    Form,
  },
  methods: {
    ...mapActions(["closeModal"]),
  },
};
</script>

<style scoped>
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background: #1a2133cc;
  overflow-y: scroll;
  padding: 127px 27px;
}
.modal__wrapper {
  max-width: 428px;
  margin: 0 auto;
}
.modal__header {
  text-align: right;
  margin-bottom: 24px;
}
.modal__header-xmark {
  transform: translateX(46px);
}
.modal__header-xmark img {
  cursor: pointer;
}
@media (max-width: 768px) {
  .modal__header-xmark {
    transform: unset;
  }
  .modal__header {
    margin-bottom: 34px;
  }
}
</style>
