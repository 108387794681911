<template>
  <div class="menu">
    <div class="menu__wrapper">
      <div class="menu__header">
        <a href="#!" class="menu__logo"><img src="/images/logo-black.svg" /></a>
      </div>
      <ul class="menu__links">
        <li>
          <router-link to="/">Главная</router-link>
        </li>
        <li v-for="(item, index) in menuItems" :key="index">
          <span @click="toggleAccordion(index)">
            <router-link to="#!">
              {{ item.name }}
              <img src="/images/icons/angle_down_gray.svg" />
            </router-link>
          </span>
          <ul
            v-show="activeAccordion === index"
            class="menu__accordion"
            @animationend="accordionComplete"
          >
            <li
              v-for="(subItem, subIndex) in item.submenu"
              :key="subIndex"
              class="menu__accordion-item"
            >
              <router-link to="#!">{{ subItem }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="/">Контакты</router-link>
        </li>
      </ul>
      <div class="menu__footer">
        <div class="menu__contact">
          <span> +7 (3412) 24–05–10</span>
          <a href="#">Заказать звонок</a>
        </div>
      </div>
    </div>
    <div class="menu__xmark">
      <img src="/images/icons/xmark.svg" @click="closeMenu" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      activeAccordion: null,
      menuItems: [
        {
          name: "О компании",
          submenu: [
            "Контакты",
            "О компании",
            "Вакансии",
            "Новости и акции",
            "FAQ",
          ],
        },
        {
          name: "Продукция",
          submenu: ["Бетон", "ЖБИ"],
        },
        {
          name: "Услуги",
          submenu: ["Лаборатория", "Аренда спецтехники", "Консультация"],
        },
      ],
    };
  },
  methods: {
    toggleAccordion(index) {
      this.activeAccordion = this.activeAccordion === index ? null : index;
    },
    accordionComplete(e) {
      if (e.animationName === "slideOut") {
        e.target.style.display = "none";
      }
    },
    ...mapActions(["closeMenu"]),
  },
};
</script>

<style scoped>
.menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  background: #1a2133cc;
}
.menu__xmark {
  height: 132px;
  padding-right: 27px;
  display: flex;
  align-items: center;
}
.menu__wrapper {
  width: 296px;
  height: 100%;
  padding: 0 28px;
  padding-bottom: 24px;
  background: white;
  display: grid;
  grid-template-rows: max-content auto 70px;
}
.menu__header {
  padding: 28px 0;
  border-bottom: 2px solid #1a213333;
}
.menu__links {
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
}
.menu__links li:hover .header__dropdown {
  height: max-content;
  padding: 24px 24px;
  overflow: hidden;
  border: 1px solid #1a213333;
  opacity: 1;
}
.menu__links li a {
  font-size: 17px;
  font-weight: 600;
  line-height: 20.72px;
  display: flex;
  align-items: center;
  color: #1a2133;
  transition: 0.2s;
}
.menu__links a:hover {
  color: #bf4017;
}
.menu__links a:hover img {
  filter: invert(41%) sepia(47%) saturate(2073%) hue-rotate(353deg)
    brightness(92%) contrast(89%);
}
.menu__links li a img {
  transform: translateX(17px);
  margin-right: 5px;
}
.menu__accordion {
  overflow: hidden;
  height: 0;
  opacity: 0;
  padding: 24px 0;
  display: grid;
  gap: 14px;
  border-bottom: 1px solid #1a213333;
}
.menu__accordion[style] {
  height: max-content;
  opacity: 1;
}
.menu__accordion-item a {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.menu__footer {
  display: flex;
  align-items: end;
}
.menu__contact {
  height: max-content;
  display: grid;
  gap: 4px;
}
.menu__contact span {
  font-size: 21px;
  font-weight: 600;
  line-height: 25.6px;
  color: #1a2133;
}
.menu__contact a {
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  color: #bf4017;
  text-decoration: underline;
  transition: 0.2s;
}
.menu__contact a:hover {
  color: #1a2133;
}
</style>
