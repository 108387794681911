<template>
  <main>
    <HeaderMenu v-if="isMenulVisible" @close="closeMenu" />
    <transition name="fade">
      <FormModal v-if="isModalVisible" @close="closeModal" />
      <HeaderSticky v-if="isHeaderVisible" />
    </transition>
    <PhonePulse v-if="showPhonePulse" />
    <router-view />
    <Footer />
  </main>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import HeaderSticky from "./components/HeaderSticky.vue";
import FormModal from "./components/modals/FormModal.vue";
import PhonePulse from "./components/PhonePulse.vue";
import HeaderMenu from "./components/HeaderMenu.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Footer,
    HeaderSticky,
    FormModal,
    PhonePulse,
    Header,
    HeaderMenu,
  },
  data() {
    return {
      isHeaderVisible: false,
      showPhonePulse: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState(["isModalVisible", "isMenulVisible"]),
  },
  methods: {
    handleScroll() {
      this.isHeaderVisible = window.scrollY > 200;
      this.showPhonePulse = window.scrollY > 200;
    },
    ...mapActions([
      "openModal",
      "closeModal",
      "toggleModal",
      "openMenu",
      "closeMenu",
    ]),
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
