<template>
  <div class="dropdown">
    <ul class="dropdown__links">
      <li v-for="item of dropdown_items" :key="item.id">
        <router-link to="#1">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    dropdown_items: Array,
  },
};
</script>

<style scoped>
.dropdown {
  width: 220px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #1a213333;
  border-radius: 6px;
}
.dropdown__links {
  display: grid;
  gap: 14px;
}
.dropdown__links li a {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #1a2133;
  transition: 0.2s;
}
.dropdown__links a:hover {
  color: #bf4017;
}
</style>
