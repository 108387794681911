<template>
  <header class="header">
    <div class="container">
      <div class="header__wrapper">
        <div class="header__left">
          <a href="#!" class="header__logo"
            ><img src="/images/logo-black.svg"
          /></a>
          <ul class="header__links">
            <li>
              <router-link to="#!">Главная </router-link>
            </li>
            <li>
              <router-link to="#!"
                >О компании <img src="/images/icons/angle_down_gray.svg"
              /></router-link>
              <DropDown
                class="header__dropdown"
                :dropdown_items="dropdown_items.item1"
              />
            </li>
            <li>
              <router-link to="#!"
                >Продукция <img src="/images/icons/angle_down_gray.svg"
              /></router-link>
              <DropDown
                class="header__dropdown"
                :dropdown_items="dropdown_items.item2"
              />
            </li>
            <li>
              <router-link to="#!"
                >Услуги <img src="/images/icons/angle_down_gray.svg"
              /></router-link>
              <DropDown
                class="header__dropdown"
                :dropdown_items="dropdown_items.item3"
              />
            </li>
            <li><router-link to="#!">Контакты</router-link></li>
          </ul>
        </div>
        <div class="header__right">
          <div class="header__contact">
            <span> +7 (3412) 24–05–10</span>
            <a href="#" @click="openModal">Заказать звонок</a>
          </div>
          <div class="header__burger" @click="openMenu">
            <img src="/images/icons/bars-black.svg" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import DropDown from "@/components/DropDown.vue";
import { mapActions } from "vuex";

export default {
  components: {
    DropDown,
  },
  data() {
    return {
      dropdown_items: {
        item1: [
          {
            name: "Контакты",
            path: "/contact",
          },
          {
            name: "О компании",
            path: "/about",
          },
          {
            name: "Вакансии",
            path: "/vacancy",
          },
          {
            name: "Новости и акции",
            path: "/news",
          },
          {
            name: "FAQ",
            path: "/faq",
          },
        ],
        item2: [
          {
            name: "Бетон",
            path: "/about",
          },
          {
            name: "ЖБИ",
            path: "/vacancy",
          },
        ],
        item3: [
          {
            name: "Лаборатория",
            path: "/about",
          },
          {
            name: "Аренда спецтехники",
            path: "/vacancy",
          },
          {
            name: "Консультация",
            path: "/faq",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions(["openModal", "openMenu"]),
  },
};
</script>
<style scoped>
.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20;
  background: white;
  border-bottom: 2px solid #1a213333;
}
.header__wrapper {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgb(217, 217, 217, 0.2);
}
.header__left {
  display: flex;
  align-items: center;
  gap: 54px;
}

.header__links {
  display: flex;
  align-items: center;
  gap: 44px;
}
.header__links li {
  padding: 14px 0;
  position: relative;
}
.header__links li:hover a {
  color: #bf4017;
}
.header__links li:hover a img {
  filter: invert(41%) sepia(47%) saturate(2073%) hue-rotate(353deg)
    brightness(92%) contrast(89%);
}

.header__dropdown {
  position: absolute;
  top: 100%;
  height: 0px;
  padding: 0px 24px;
  overflow: hidden;
  transition: 0.3s ease;
  opacity: 0;
}
.header__links li:hover .header__dropdown {
  height: max-content;
  padding: 24px 24px;
  overflow: hidden;
  border: 1px solid #1a213333;
  opacity: 1;
}
.header__links li a {
  font-size: 17px;
  font-weight: 600;
  line-height: 20.72px;
  display: flex;
  align-items: center;
  color: #1a2133;
  transition: 0.2s;
}
.header__links a:hover {
  color: #bf4017;
}
.header__links a:hover img {
  filter: invert(41%) sepia(47%) saturate(2073%) hue-rotate(353deg)
    brightness(92%) contrast(89%);
}
.header__links li a img {
  transform: translateX(17px);
  margin-right: 5px;
}
.header__right {
  display: flex;
  align-items: center;
  gap: 40px;
}
.header__contact {
  display: grid;
  text-align: right;
  gap: 4px;
}
.header__contact span {
  font-size: 21px;
  font-weight: 600;
  line-height: 25.6px;
  color: #1a2133;
}
.header__contact a {
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  color: #bf4017;
  text-decoration: underline;
  transition: 0.2s;
}
.header__contact a:hover {
  color: #1a2133;
}
.header__burger {
  width: 44px;
  height: 44px;
  display: none;
  place-items: center;
  border: 1px solid #1a213333;
  border-radius: 8px;
}
@media (max-width: 1279px) {
  .header__links {
    display: none;
  }
  .header__burger {
    display: grid;
  }
}
@media (max-width: 767px) {
  .header__wrapper {
    padding: 8px 0;
  }
  .header__contact {
    display: none;
  }
}
</style>
