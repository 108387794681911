import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VMask from "@ssibrahimbas/v-mask";
import "aos/dist/aos.css";
import AOS from "aos";
import store from "./store/store";

Vue.config.productionTip = false;
Vue.use(VMask);

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");

AOS.init({
  once: true,
});
