<template>
  <section class="contact section-padding">
    <div class="container">
      <div class="contact__header section-header">
        <h2 class="contact__header-title">Контакты</h2>
        <div class="contact__header-buttons">
          <button
            class="contact__header-button contact__header-button-active btn btn-sm"
          >
            Новоайдар
          </button>
          <button class="contact__header-button btn btn-sm">Луганск</button>
        </div>
      </div>
      <div class="contact__map">
        <div id="yandex-map"></div>
        <div class="contact__map-info">
          <div class="contact__map-info-icon">
            <img src="/images/icons/location.svg" />
          </div>
          <div class="contact__map-info-right">
            <p>
              ЛНР, М.О Нововоайдарский, пгт Новоайдар, ул. Октябрьская, д. 2А
            </p>
            <a href="#!">enkibeton@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    const script = document.createElement("script");
    script.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
    script.onload = this.initMap;
    document.head.appendChild(script);
  },
  methods: {
    initMap() {
      ymaps.ready(() => {
        const map = new ymaps.Map("yandex-map", {
          center: [48.935503, 39.039749],
          zoom: 20,
          controls: [],
        });

        const customPlacemark = new ymaps.Placemark(
          [48.935503, 39.039749],
          {
            balloonContent: "ЛНР, Новоайдар, ул. Октябрьская, д. 2А",
          },
          {
            iconLayout: "default#image",
            iconImageHref: "/images/icons/map_mark.svg",
            iconImageSize: [30, 42],
            iconImageOffset: [-15, -42],
          }
        );

        map.geoObjects.add(customPlacemark);
      });
    },
  },
};
</script>

<style scoped>
.section-padding {
  padding-bottom: 110px;
}

.contact__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact__header-buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}

.contact__header-button {
  border: 2px solid #1a213333;
  color: #1a2133;
  transition: 0.2s;
}

.contact__header-button:hover {
  background: #bf4017;
  color: white;
  border-color: #bf4017;
}

.contact__header-button-active {
  background: #bf4017;
  color: white;
  border-color: #bf4017;
}

.contact__map {
  border: 2px solid #1a21334d;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
#yandex-map {
  width: 100%;
  height: 540px;
}
.contact__map-info {
  position: absolute;
  left: 34px;
  bottom: 34px;
  width: 430px;
  padding: 24px 34px;
  display: grid;
  grid-template-columns: 26px auto;
  gap: 25px;
  background: white;
  border: 2px solid #1a21334d;
  border-radius: 6px;
}
.contact__map-info-icon {
  margin-top: 19px;
}
.contact__map-info-icon img {
  width: 27px;
  height: 38px;
}

.contact__map-info-right p {
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  color: #1a2133;
  margin-bottom: 14px;
}

.contact__map-info-right a {
  font-size: 19px;
  font-weight: 600;
  line-height: 28px;
  color: #bf4017;
  text-decoration: underline;
  transition: 0.2s;
}

.contact__map-info-right a:hover {
  color: #1a2133;
}
@media (max-width: 768px) {
  .contact__header {
    display: grid;
    justify-content: unset !important;
    gap: 34px;
  }
  .contact__map-info {
    display: none;
  }
  #yandex-map {
    width: 100%;
    height: 480px;
  }
}
@media (max-width: 576px) {
  .contact__header {
    padding-bottom: 24px;
  }
  .contact__header-buttons {
    display: grid;
    grid-template-columns: auto auto;
  }
  .contact__header-button {
    height: 59px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 30px;
  }

  #yandex-map {
    height: 320px;
  }
}
</style>
