<template>
  <div class="phone" @click="openModal">
    <div class="phone__wrapper pulse">
      <a href="#!" class="phone__icon">
        <img src="/images/icons/phone_icon.svg" />
      </a>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["openModal"]),
  },
};
</script>
<style scoped>
.phone {
  position: fixed;
  left: 86px;
  bottom: 50px;
  z-index: 20;
}

.pulse {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  background: #bf4017;
  border-radius: 50%;
  color: #fff;
  font-family: sans-serif;
  animation: animate-pulse 3s linear infinite;
  cursor: pointer;
}
.phone__icon {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(191, 64, 23, 0.7), 0 0 0 0 rgba(191, 64, 23, 0.7);
  }
  40% {
    box-shadow: 0 0 0 20px rgba(191, 64, 23, 0), 0 0 0 0 rgba(191, 64, 23, 0.7);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(191, 64, 23, 0), 0 0 0 20px rgba(191, 64, 23, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(191, 64, 23, 0), 0 0 0 20px rgba(191, 64, 23, 0);
  }
}
@media (max-width: 1630px) {
  .phone {
    left: 30px;
    bottom: 40px;
    z-index: 20;
  }
}
</style>
