var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"footer__header"},[_vm._m(0),_c('button',{staticClass:"footer__header-button btn btn-sm btn-red",on:{"click":_vm.openModal}},[_vm._v(" Заказать звонок ")])]),_c('div',{staticClass:"footer__middle"},[_c('div',{staticClass:"footer__item"},[_c('h5',{staticClass:"footer__item-title"},[_vm._v("Контакты")]),_c('div',{staticClass:"footer__item-text"},[_vm._v(" ЛНР, М.О Нововоайдарский, пгт Новоайдар, ул. Октябрьская, д. 2А ")]),_c('div',{staticClass:"footer__item-bottom"},[_c('div',{staticClass:"footer__contact"},[_c('span',[_vm._v(" +7 (3412) 24–05–10")]),_c('a',{attrs:{"href":"#!"},on:{"click":_vm.openModal}},[_vm._v("Заказать звонок")])]),_vm._m(1)])]),_vm._m(2),_vm._m(3),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footer__logo",attrs:{"href":"#!"}},[_c('img',{attrs:{"src":"/images/logo.svg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__socials"},[_c('a',{staticClass:"footer__socials-item",attrs:{"href":"#!"}},[_c('img',{attrs:{"src":"/images/icons/telegram.svg"}})]),_c('a',{staticClass:"footer__socials-item",attrs:{"href":"#!"}},[_c('img',{attrs:{"src":"/images/icons/whatsapp.svg"}})]),_c('a',{staticClass:"footer__socials-item",attrs:{"href":"#!"}},[_c('img',{attrs:{"src":"/images/icons/wk.svg"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__item"},[_c('h5',{staticClass:"footer__item-title"},[_vm._v("О компании")]),_c('ul',{staticClass:"footer__item-links"},[_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("О компании")])]),_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("Контакты")])]),_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("Вакансии")])]),_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("Новости и акции")])]),_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("FAQ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__item"},[_c('h5',{staticClass:"footer__item-title"},[_vm._v("Продукция")]),_c('ul',{staticClass:"footer__item-links"},[_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("Бетон")])]),_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("ЖБИ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__item"},[_c('h5',{staticClass:"footer__item-title"},[_vm._v("Услуги")]),_c('ul',{staticClass:"footer__item-links"},[_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("Лаборатория")])]),_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("Аренда спецтехники")])]),_c('li',[_c('a',{attrs:{"href":"#!"}},[_vm._v("Консультация")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__bottom"},[_c('p',[_vm._v("© 2024 «Энки Бетон ЛНР»")])])
}]

export { render, staticRenderFns }