import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let body = document.querySelector("body");

export default new Vuex.Store({
  state: {
    isModalVisible: false,
    isMenulVisible: false,
  },
  mutations: {
    openModal(state) {
      state.isModalVisible = true;
      body.style.overflowY = "hidden";
    },
    closeModal(state) {
      state.isModalVisible = false;
      body.style.overflowY = "scroll";
    },
    toggleModal(state) {
      state.isModalVisible = !state.isModalVisible;
    },
    // Header menu
    openMenu(state) {
      state.isMenulVisible = true;
      body.style.overflowY = "hidden";
    },
    closeMenu(state) {
      state.isMenulVisible = false;
      body.style.overflowY = "scroll";
    },
  },
  actions: {
    openModal({ commit }) {
      commit("openModal");
    },
    closeModal({ commit }) {
      commit("closeModal");
    },
    toggleModal({ commit }) {
      commit("toggleModal");
    },
    // Header menu
    openMenu({ commit }) {
      commit("openMenu");
    },
    closeMenu({ commit }) {
      commit("closeMenu");
    },
  },
});
